<script>
    import { onMount } from 'svelte';
    import Icon from './Icon.svelte';
    import { fade } from 'svelte/transition';

    let className = '';
    export { className as class };

    export let content_class = '';

    export let scroll_step = 300;

    /** @type {HTMLDivElement}*/
    let scrollable_area;
    let at_start = true;
    let at_end = true;

    const calc_start_end = () => {
        at_start = scrollable_area?.scrollLeft === 0;
        at_end =
            scrollable_area?.scrollLeft + scrollable_area?.offsetWidth >=
            scrollable_area?.scrollWidth;
    }

    const handle_scroll_left = () => {
        let scroll_step_a = Math.max(scroll_step, scrollable_area.clientWidth / 2);
        
        scrollable_area.scrollBy({
            left: -1 * Math.min(scroll_step_a, scrollable_area.scrollLeft),
            behavior: 'smooth',
        });
        calc_start_end();
    };

    const handle_scoll_right = () => {
        let scroll_step_a = Math.max(scroll_step, scrollable_area.clientWidth / 2);
        scrollable_area.scrollBy({
            left: scroll_step_a,
            behavior: 'smooth'
        });
        calc_start_end();
    };

    onMount(()=>{
        calc_start_end();
        scrollable_area.addEventListener('scroll', calc_start_end);
    })
</script>

<section class="relative {className}">
    <div
        bind:this={scrollable_area}
        class="overflow-x-auto max-sm:snap-x snap-mandatory overflow-gradient-x scroll-hide overscroll-x-contain {content_class}"
    >
        <slot />
    </div>

    {#if !at_start}
        <button
            in:fade
            class="collapse md:visible absolute left-0 top-1/2 -translate-y-1/2 bg-black rounded-full"
            on:mousedown={handle_scroll_left}><Icon arrow_circle_left xl /></button
        >
    {/if}

    {#if !at_end}
        <button
            in:fade
            class="collapse md:visible absolute right-0 top-1/2 -translate-y-1/2 bg-black rounded-full"
            on:mousedown={handle_scoll_right}><Icon arrow_circle_right xl /></button
        >
    {/if}

</section>
